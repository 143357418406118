import { defineStore } from "pinia";
import Big from "@/plugins/big";
import schema from "@/http/apollo/schema";
import storage from "../modules/storage";

import { useConfigStore } from "../configStore";

export const useLocationStore = defineStore("locationStore", {
  state: () => ({
    compraGratis: 0,
    compraMinima: 0,
    data: {
      postalCode: "",
      city: "",
      state: "",
    },
    discountValue: 0,
    frete: 0,
    indeterminate: true,
    minimo: 0,
    restante: 0,
    minimalPurchase: 0,
    useLocationCheckout: false,
    userLocation: storage.userLocation(),
    deliveryDeadline: "",
    shippingType: "",
    shippingId: null,
  }),

  getters: {
    discount(state) {
      const d = Big(state.discountValue);
      return d.div(100).toFixedNumber();
    },
    hasMinimalPurchase(state) {
      return !!state.minimo;
    }
  },

  actions: {
    async calcFrete(amount, addressId) {
      if (!amount) {
        this.indeterminate = false;
        return;
      }

      if (!this.data.postalCode) {
        this.indeterminate = false;
        return;
      }

      const config = useConfigStore();

      const {
        data: { calcFreight },
      } = await window.$apollo.query({
        query: schema.store.FRETE_CALC,
        variables: {
          postalCode: this.data.postalCode,
          storeId: config.currentStore,
          price: amount,
          address: addressId,
        },
      });

      const {
        freeShippingPrice,
        minimalPurchase,
        price,
        discount,
      } = calcFreight;

      const amountBig = Big(amount);
      this.minimalPurchase = minimalPurchase ?? 0;
      this.shippingType = calcFreight.type;
      this.shippingId = calcFreight.shippingId;

      if (freeShippingPrice) {
        const freePriceBig = Big(freeShippingPrice);
        const freePricePer = amountBig
          .div(freePriceBig)
          .mul(100)
          .toFixedNumber();

        this.setCompraGratis(
          amountBig.lt(freePriceBig) ? freePricePer : 100
        );
        this.setCompraMinima(0);
        this.restante = freePriceBig.gte(amountBig)
          ? freePriceBig.sub(amountBig).toFixedNumber()
          : 0;
      }

      if (minimalPurchase && amountBig.lt(minimalPurchase)) {
        const minimalPurchaseBig = Big(minimalPurchase);
        const minimalPurchasePer = amountBig
          .div(minimalPurchaseBig)
          .mul(100)
          .toFixedNumber();

        this.setCompraGratis(0);
        this.setCompraMinima(minimalPurchasePer);
        this.minimo = minimalPurchaseBig.gte(amountBig)
          ? minimalPurchaseBig.sub(amountBig).toFixedNumber()
          : 0;
      }

      let deadline = JSON.parse(calcFreight.extra || null)?.deliveryDeadline;
      if (!deadline?.value) {
        deadline = config.storeConfig.deliveryDeadline;
      }
      this.deliveryDeadline = deadline?.value
        ? `${deadline?.value} ${deadline?.type}`
        : "";

      this.frete = price;
      this.setDiscount(discount);
    },

    cleanFrete() {
      this.indeterminate = true;
      this.compraGratis = 0;
      this.compraMinima = 0;
      this.discountValue = 0;
      this.frete = 0;
      this.restante = 0;
      this.minimo = 0;
      this.minimalPurchase = 0;
      this.shippingId = null;
      this.shippingType = "";
    },

    setCompraGratis(value) {
      this.compraGratis = value;
    },

    setCompraMinima(value) {
      this.compraMinima = value;
    },

    setDiscount(value) {
      this.discountValue = value ?? 0;
    },

    setIndeterminate(value) {
      this.indeterminate = value;
    },

    removeDiscount() {
      this.discountValue = 0;
    },

    setUserLocation(value) {
      this.userLocation = value || null;
      storage.setUserLocation(value);
    },

    setCurrentPosition(coords) {
      this.data.coords = coords;
      this.setUserLocation({ ...this.data });
    },

    setLocationCheckout(value) {
      this.useLocationCheckout = value;
    },
  },
});
