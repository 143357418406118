import { defineStore } from "pinia";
import schema from "@/http/apollo/schema";
import Big from "@/plugins/big";
import { calcTotal, calcTotalWithoutPromoItems } from "@/lib/items";
import { useConfigStore } from "@/storex/configStore";
import storage from "../modules/storage";

const showMessage = (text, error = false, cb) => {
  if (error) {
    window.$alert.show({
      text,
      actions: [
        { text: "Fechar" },
        {
          text: cb ? "REMOVER CUPOM" : "OK",
          color: "error",
          onPress: cb,
        },
      ],
    });
    return;
  }
  window.$snackbar.show({
    text,
    color: "success",
    action: { text: "OK" },
  });
};

export const useCouponStore = defineStore("couponStore", {
  state: () => ({
    couponCode: storage.couponCode(),
    couponCodeLoading: false,
    value: "",
    validCode: "null",
    error: null,
    itemsTotal: null,
  }),

  getters: {
    isValidCoupon(state) {
      if (state.couponCodeLoading) {
        return false;
      }
      if (!state.couponCode?.couponCode) {
        return false;
      }
      if (state.value !== state.validCode) {
        return false;
      }
      return true;
    },

    ignorePromotionalItems(state) {
      if (this.isValidCoupon) {
        return state.couponCode.ignorePromotionalItems;
      }
      return false;
    },

    ignoreDiscountRules(state) {
      if (this.isValidCoupon) {
        return state.couponCode.ignoreDiscountRules;
      }
      return false;
    },

    ready(state) {
      if (state.value?.length) {
        return this.isValidCoupon;
      }
      return true;
    },
  },

  actions: {
    setValue(value) {
      this.value = value;
      if (this.error) {
        this.error = null;
      }
    },

    setCouponCodeData(value) {
      this.couponCode = value || null;
      storage.setCouponCode(value);
    },

    getSubTotal(subTotalValue = 0, totalWithoutPromoItems = 0) {
      if (this.itemsTotal !== null) {
        return Big(this.itemsTotal);
      }
      if (this.ignorePromotionalItems) {
        return Big(totalWithoutPromoItems);
      }
      return Big(subTotalValue);
    },

    getCouponDiscount(subTotalValue = 0, totalWithoutPromoItems = 0) {
      if (!this.couponCode?.discountValue) {
        return 0;
      }
      const minimal = this.couponCode.minimalOrderValue;
      const subTotal = this.getSubTotal(subTotalValue, totalWithoutPromoItems);
      if (minimal && Big(minimal).gt(subTotal)) {
        return 0;
      }

      const discountBig = Big(this.couponCode.discountValue);
      if (this.couponCode.discountType === "value") {
        return discountBig.toFixedNumber();
      }

      return subTotal.mul(discountBig).div(100).toFixedNumber();
    },

    async setCouponCode({
      value,
      subTotalValue,
      totalWithoutPromoItemsValue,
      skipValidations,
      items,
      onError,
    }) {
      if (!value) {
        this.reset();
        return;
      }
      const currentValue = this.validCode;

      try {
        this.couponCodeLoading = true;
        const config = useConfigStore();

        const req = {
          query: schema.coupon.VALIDATE_DISCOUNT_COUPON,
          variables: {
            couponCode: value.toUpperCase(),
            storeId: config.currentStore,
            skipUserValidation: skipValidations,
          },
        };

        const { data } = await window.$apollo.query(req);

        const res = data.validateDiscountCoupon;
        if (!res?.valid) {
          const msg = "Cupom não é válido";
          this.setCouponCodeData(null);
          this.error = msg;
          showMessage(msg, true, this.reset);
          onError?.();
          return;
        }

        if (skipValidations) {
          this.setCouponCodeData(res.data);
          this.setValue(res.data.couponCode);
          this.validCode = res.data.couponCode;
          showMessage("Cupom aplicado com sucesso!");
          return;
        }

        if (subTotalValue === undefined || totalWithoutPromoItemsValue === undefined) {
          console.error("Missing subTotalValue or totalWithoutPromoItemsValue");
        }

        const minimal = Big(res.data.minimalOrderValue ?? 0);
        let subTotal = Big(subTotalValue);

        if (res.data.ignorePromotionalItems) {
          subTotal = Big(totalWithoutPromoItemsValue);
        }

        if (subTotal.eq(0) || minimal.gt(subTotal)) {
          const val = window.$utils.decimalBRL(minimal.toFixedNumber());
          let msg = `Compra mínima de ${val} para aplicar o cupom`;
          if (res.data.ignorePromotionalItems) {
            msg = `
              Este cupom não se aplica para produtos promocionais.
              Compra mínima de ${val}.
            `;
            if (subTotal.eq(0)) {
              msg = "Este cupom não é válido em produtos promocionais";
            }
          }
          this.setCouponCodeData(null);
          this.error = msg;
          showMessage(msg, true, this.reset);
          onError?.();
          return;
        }

        if (items?.length && res.data.categories?.length) {
          const valid = await this.validateCategories(
            items,
            res.data,
            config.currentStore,
          );
          if (!valid) {
            const msg = "Este cupom não é válido para os produtos do carrinho";
            this.setCouponCodeData(null);
            this.error = msg;
            showMessage(msg, true, this.reset);
            onError?.();
            return;
          }
        }

        this.setCouponCodeData(res.data);
        this.setValue(res.data.couponCode);
        this.validCode = res.data.couponCode;
        if (currentValue !== res.data.couponCode) {
          showMessage("Cupom aplicado com sucesso!");
        }
      } catch {
        const msg = "Não foi possível aplicar o cupom";
        this.setCouponCodeData(null);
        this.error = msg;
        showMessage(msg, true, this.reset);
        onError?.();
      } finally {
        this.couponCodeLoading = false;
      }
    },

    async validateCategories(items, coupon, storeId) {
      try {
        const ids = items.map((i) => i.item.id);
        const categories = coupon.categories.map((c) => c.categoryId);

        const options = {
          query: schema.items.ITEMS_ID_BY_CATEGORY,
          variables: { categories, ids, storeId },
        };
        const { data } = await window.$apollo.query(options);

        const resultItems = items.filter((i) => {
          return !!data.items.data.find((d) => d.id === i.item.id);
        });

        if (!resultItems.length) {
          return false;
        }

        this.setItemsTotal(resultItems, coupon);

        return true;
      } catch {
        return false;
      }
    },

    setItemsTotal(items, coupon) {
      if (coupon.ignorePromotionalItems) {
        this.itemsTotal = calcTotalWithoutPromoItems(items, {
          ignoreDiscountRules: coupon.ignoreDiscountRules,
        });
      } else {
        this.itemsTotal = calcTotal(items, {
          ignoreDiscountRules: coupon.ignoreDiscountRules,
        });
      }
    },

    reset() {
      this.setCouponCodeData(null);
      this.setValue("");
      this.validCode = "null";
      this.itemsTotal = null;
    },
  },
});
