import { gql } from "@apollo/client/core";

export const CATEGORIES = gql`
  query getCategories(
    $storeId: ID!
    $parentId: Int
    $name: String
    $status: String
    $orderBy: [OrderByClause!]
    $first: Int!
    $page: Int
  ) {
    categories(
      storeId: $storeId
      parentId: $parentId
      name: $name
      status: $status
      orderBy: $orderBy
      first: $first
      page: $page
    ) {
      data {
        id
        storeId
        name
        parentId
        status
        childs {
          id
          storeId
          name
          parentId
          status
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
`;

export const CATEGORIES_ALL = gql`
  query getCategories(
    $storeId: ID!
    $orderBy: [OrderByClause!]
  ) {
    categories: categoriesAll(
      storeId: $storeId
      orderBy: $orderBy
    ) {
      id
      storeId
      name
      icon
      parentId
      status
    }
  }
`;

export const FRETE_CALC = gql`
  query calcFreight(
    $postalCode: String!
    $storeId: Int!
    $price: Float
    $address: Int
  ) {
    calcFreight(
      postalCode: $postalCode
      storeId: $storeId
      price: $price
      addressId: $address
    ) {
      freeShippingPrice
      minimalPurchase
      price
      discount
      extra
      type
      shippingId
    }
  }
`;

export const ITEM = gql`
  query getItem($id: ID! $storeId: ID!) {
    item(id: $id storeId: $storeId) {
      code
      description
      stockQuantity
      id
      images
      name
      price
      promotionalPrice
      attrs
      categories {
        id
        name
        parentId
        status
        childs {
          id
          name
          parentId
          status
        }
      }
      variants(deleted: false) {
        id
        attrs
      }
    }
  }
`;

export const ITENS = gql`
  query getItens(
    $categories: [Int!]
    $deleted: Boolean
    $first: Int!
    $page: Int!
    $storeId: ID!
    $listPriceId: ID
    $ids: [ID!]
    $q: String
    $where: QueryItemsWhereWhereConditions
  ) {
    items(
      categories: $categories
      deleted: $deleted
      first: $first
      orderBy: [
        { column: "stock_quantity" order: DESC }
        { column: "ranking" order: DESC }
      ]
      page: $page
      storeId: $storeId
      listPriceId: $listPriceId
      ids: $ids
      q: $q
      where: $where
    ) {
      data {
        attrs
        code
        description
        id
        images
        name
        price
        promotionalPrice
        stockQuantity
        storeId
        listPrice
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
`;

export const PAYMENTS = gql`
  query getPayments($id: ID) {
    payments: store(id: $id) {
      paymentMethods {
        gateway
        id
        label
        supportedNetworks
        supportedTypes
        type
        installments
      }
      shippingOptions {
        amount
        id
        label
        type
        usePostalCodes
      }
    }
  }
`;

export const PAYMENTS_ADDRESS = gql`
  query getPayments($id: ID) {
    payments: store(id: $id) {
      paymentMethods {
        gateway
        id
        label
        supportedNetworks
        supportedTypes
        type
        installments
      }
      shippingOptions {
        amount
        id
        label
        type
        usePostalCodes
      }
    }
    address: me {
      province
      postalCode
      complement
      address
      addresses {
        province
        postalCode
        complement
        address
        deleted
        id
        latitude
        longitude
        city
        addressNumber
        references
        type
        state
      }
      id
      city
      addressNumber
      state
      references
      latitude
      longitude
    }
  }
`;

export const PEDIDO = gql`
  query getPedido($id: ID!) {
      order(id: $id) {
      id
      createdAt
      updatedAt
      status
      statusMessage
      total
      observation
      fiscalDocument
      fiscalDocumentLink
      address {
        id
        type
        postalCode
        addressNumber
        province
        address
        city
        state
      }
      items {
        item {
          id
          code
          images
          name
        }
        quantity
        price
        total
      }
      payments {
        id
        type
        gateway
        billingType
        transaction
      }
      statusStory {
        id
        createdAt
        status
        message
      }
    }
  }
`;

export const PEDIDOS = gql`
  query getPedidos(
    $first: Int!
    $page: Int!
    $orderBy: [OrderByClause!]
  ) {
    orders(
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        createdAt
        status
        total
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
`;

export const SHOPPING_CART = gql`
  query getShoppingCart {
    me {
      shoppingCart {
        total
        items {
          id
          quantity
          item {
            id
            code
            name
            price
            promotionalPrice
            images
          }
        }
      }
    }
  }
`;

export const SET_SHOPPING_CART_ITEM = gql`
  mutation($items: [ShoppingCartItemInput!]!) {
    ids: setShoppingCartItem(items: $items)
  }
`;

export const UNSET_SHOPPING_CART_ITEM = gql`
  mutation($id: ID!) {
    unsetShoppingCartItem(id: $id)
  }
`;

export const CLEAR_SHOPPING_CART = gql`
  mutation {
    clearShoppingCart
  }
`;

export const CANCELAR_PEDIDO_MUTATION = gql`
  mutation($id: ID!) {
    cancelOrder(id: $id)
  }
`;

export const PEDIDO_MUTATION = gql`
  mutation(
    $addressId: Int
    $discountCouponCode: String
    $items: [OrderItemInput]
    $observation: String
    $paymentCard: CardInput
    $paymentMethod: Int
    $shippingOption: Int
    $total: Float!
  ) {
    orderId: createOrder(
      addressId: $addressId
      discountCouponCode: $discountCouponCode
      items: $items
      observation: $observation
      paymentCard: $paymentCard
      paymentMethod: $paymentMethod
      shippingOption: $shippingOption
      total: $total
    )
  }
`;

export const SET_AVISO_ESTOQUE = gql`
  mutation(
    $storeId: Int!
    $itemId: Int!
  ) {
    setStockAlert(
      storeId: $storeId
      itemId: $itemId
    )
  }
`;

export const STORE = gql`
  query getStore($id: ID!) {
    store (id: $id) {
      name
      cpfCnpj
      whatsapp
      phone
      email
      site
      latitude
      longitude
      address
      addressNumber
      province
      complement
      postalCode
      city
      state
    }
  }
`;

const schema = {
  CANCELAR_PEDIDO_MUTATION,
  CATEGORIES_ALL,
  CATEGORIES,
  CLEAR_SHOPPING_CART,
  FRETE_CALC,
  ITEM,
  ITENS,
  PAYMENTS_ADDRESS,
  PAYMENTS,
  PEDIDO_MUTATION,
  PEDIDO,
  PEDIDOS,
  SET_AVISO_ESTOQUE,
  SET_SHOPPING_CART_ITEM,
  SHOPPING_CART,
  STORE,
  UNSET_SHOPPING_CART_ITEM,
};

export default schema;
