import Big from "@/plugins/big";
import { getOrderTotal, OrderFactory } from "@/lib/order";
import { getDiscountLabel } from "@/lib/items";
import { useLocationStore } from "@/storex/checkout/locationStore";
import { useValidationStore } from "@/storex/validationStore";
import { useCouponStore } from "@/storex/checkout/couponStore";

export default {
  loading(state) {
    return state.loading;
  },
  paymentMethods(state) {
    return state.paymentMethods;
  },
  shippingOptions(state) {
    return state.shippingOptions;
  },
  subTotal(state) {
    return state.subTotal;
  },
  subTotalLabel(state) {
    return state.subTotalLabel;
  },
  totalWithoutPromoItems(state) {
    return state.totalWithoutPromoItems;
  },
  userNotes(state) {
    return state.userNotes;
  },
  pendingOrder(state) {
    return !!state.shippingOptions.shipping || !!state.paymentMethods.payment;
  },
  currentPostalCode(state) {
    return state.currentPostalCode;
  },
  stepper(state) {
    return state.stepper;
  },
  recaptcha(state) {
    if (process.env.NODE_ENV !== "production") {
      return true;
    }
    return state.recaptcha;
  },
  discountRules(state) {
    return state.discountRules;
  },
  defaultDiscountRule(state) {
    return state.defaultDiscountRule;
  },
  discountRulesEnabled(state) {
    const location = useLocationStore();
    const coupon = useCouponStore();

    if (location.discount) {
      return false;
    }
    if (coupon.ignoreDiscountRules) {
      const couponDiscount = coupon.getCouponDiscount(
        state.subTotal,
        state.totalWithoutPromoItems,
      );
      if (couponDiscount) {
        return false;
      }
    }
    return !!state.discountRules.length;
  },
  discountRulesSelected(state) {
    const pay = state.paymentMethods?.payment;
    if (pay?.type === "gateway" && !state.paymentMethods.billingType) {
      return null;
    }
    return state.discountRules.find((v) => v.paymentMethod.id === pay?.id);

  },
  discountRulesValue(_, getters) {
    const rule = getters.discountRulesSelected;
    return Big(rule?.discountValue || 0).div(100).toFixedNumber();
  },

  isEmpty(state, g, rs, rootGetters) {
    return !state.loading && !rootGetters["shopping/shopping"].total;
  },

  freightLabel(state) {
    const freight = state.shippingOptions?.shipping?.amount;
    if (freight === 0) {
      return "Grátis";
    }
    if (freight > 0) {
      return window.$vm.$i18n.locale === "en"
        ? window.$vm.$utils.USD(freight)
        : window.$vm.$utils.BRL(freight);
    }
    return "Não disponível";
  },

  computedDiscount(state, getters) {
    const location = useLocationStore();
    const coupon = useCouponStore();
    let discount = coupon.getCouponDiscount(
      state.subTotal,
      state.totalWithoutPromoItems,
    );
    const subTotal = Big(state.subTotal);

    if (discount && !coupon.ignoreDiscountRules && getters.discountRulesValue) {
      const rulesDiscount = subTotal.mul(getters.discountRulesValue);
      discount = rulesDiscount.add(discount).toFixedNumber();
    } else if (!discount) {
      const val = location.discount || getters.discountRulesValue || 0;
      discount = subTotal.mul(val).toFixedNumber();
    }

    const label =
      window.$vm.$i18n.locale === "en"
        ? window.$vm.$utils.USD(discount * -1)
        : window.$vm.$utils.BRL(discount * -1);

    return { value: discount, label };
  },

  computedDiscountRules(state, getters) {
    if (!state.discountRules || !getters.discountRulesEnabled) {
      return [];
    }

    if (getters.discountRulesSelected) {
      const { title, icon, total } = getDiscountLabel(
        getters.discountRulesSelected,
        state.subTotal
      );
      return [
        {
          title,
          subtitle: "Desconto aplicado",
          type: "success",
          icon,
          total,
        },
      ];
    }

    if (getters.discountRulesEnabled) {
      const isGateway = state.paymentMethods.payment?.type === "gateway";
      const payId = state.paymentMethods.payment?.id;
      if (payId && (!isGateway || state.paymentMethods.billingType)) {
        return [];
      }
    }

    return state.discountRules.map((v) => {
      const { title, icon, total } = getDiscountLabel(v, state.subTotal);
      return {
        title,
        subtitle: "Desconto disponível",
        type: "info",
        icon,
        total,
      };
    });
  },

  orderDetails(s, getters) {
    const loc = useLocationStore();
    const coupon = useCouponStore();
    const order = new OrderFactory()
      .addShippingAndPayment(
        getters.shippingOptions,
        getters.paymentMethods,
        {
          deliveryDeadline: loc.deliveryDeadline,
          shippingType: loc.shippingType,
          shippingId: loc.shippingId,
        },
      );
    if (coupon.value?.length && coupon.isValidCoupon) {
      const val = coupon.couponCode.couponCode;
      order.observation = `Cupom: ${val}\n${order.observation}`;
    }
    if (!order.observation) {
      return [];
    }
    return order.observation.split("\n").map((v, i) => ({
      key: `detail-${i}`,
      text: v,
    }));
  },

  totalFreight(state) {
    return state.shippingOptions?.shipping?.amount ?? 0;
  },

  total(state, getters) {
    return getOrderTotal(
      state.subTotal,
      getters.totalFreight,
      getters.computedDiscount.value,
    );
  },

  totalLabel(s, getters) {
    return window.$vm.$i18n.locale === "en"
      ? window.$vm.$utils.USD(getters.total)
      : window.$vm.$utils.BRL(getters.total);
  },

  paymentVisible(state) {
    return !state.shippingOptions?.isPickup?.();
  },

  paymentLabel(s, getters) {
    if (getters.paymentVisible) {
      return "Realize o pagamento agora pelo app, ou ao receber o seu pedido.";
    }
    return "Realize o pagamento ao retirar o seu pedido na loja.";
  },

  shippingOptionsReady(state) {
    return state.shippingOptions?.isPickup() || state.shippingOptions.isReady();
  },

  paymentMethodsReady(state) {
    return state.shippingOptions?.isPickup() || state.paymentMethods.isReady();
  },

  orderReady(state) {
    const loc = useLocationStore();
    const validation = useValidationStore();
    const coupon = useCouponStore();

    if (loc.useLocationCheckout && !loc.userLocation?.coords) {
      return false;
    }
    if (!validation.ready) {
      return false;
    }
    if (state.shippingOptions?.isPickup()) {
      return coupon.ready;
    }
    if (state.shippingOptions.isReady() && state.paymentMethods.isReady()) {
      return coupon.ready;
    }
    return false;
  },
};
