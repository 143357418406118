import Big from "@/plugins/big";
import browserHash from "@/plugins/utils/browserHash";

const getCommentsLabel = (payment, billingType, shipping, change, deadline, installment) => {
  const deliveryArray = [`\nEnvio: ${shipping?.label}`];
  deadline && deliveryArray.push(`Prazo de Entrega: ${deadline}`);
  const delivery = deliveryArray.join("\n");

  if (payment?.type === "card") {
    const ct = billingType === "credit" ? "Crédito" : "Débito";
    const s = billingType ? `${payment?.label} (${ct})` : payment?.label;
    if (billingType === "credit" && installment?.value) {
      return `Pagamento: ${s}\n${installment.label}${delivery}`;
    }
    return `Pagamento: ${s}${delivery}`;
  }

  if (payment?.type === "simple" && change) {
    let v = window.$utils.BRL(change.value);
    let t = window.$utils.BRL(change.value - change.total);
    if (change.value === -1) {
      v = window.$utils.BRL(change.total);
      t = "Sem troco";
    }
    return `Pagamento: ${payment?.label}\nDinheiro total: ${v}\nTroco: ${t}${delivery}`;
  }

  if (payment?.type === "gateway") {
    const types = payment.supportedTypes ?? [];
    const gw = window.$utils.capitalize(payment.gateway);
    if (types.includes("link")) {
      return `Link de pagamento${delivery}`;
    }
    if (types.includes("pix")) {
      return `Pagamento PIX${delivery}`;
    }
    if (types.includes("credit") || types.includes("debit")) {
      if (installment?.value) {
        return `Pagamento pelo app (cartão via ${gw})\n${installment.label}${delivery}`;
      }
      return `Pagamento pelo app (cartão via ${gw})${delivery}`;
    }
  }
  return `Pagamento: ${payment?.label || "Na loja"}${delivery}`;
};

export const getOrderTotal = (
  subTotal,
  freight,
  discount = 0,
) => {
  const freightBig = Big(freight > 0 ? freight : 0);
  const subTotalBig = Big(subTotal);
  return subTotalBig.sub(discount).add(freightBig).toFixedNumber();
};

function OrderFactory() {
  this.addressId = null;
  this.discountCouponCode = undefined;
  this.items = [];
  this.observation = "";
  this.paymentCard = null;
  this.paymentMethod = null;
  this.shippingOption = null;
  this.total = 0;
}

OrderFactory.prototype = {
  addShippingAndPayment(shippingObj, paymentObj, locationOptions = {}) {
    const { deliveryDeadline, shippingId } = locationOptions;
    const { shipping, address } = shippingObj;

    if (["delivery", "shipping"].includes(shipping?.type)) {
      this.addressId = parseInt(address?.id) || null;
    }

    const { payment, billingType, change, paymentCard, selectedInstallment } = paymentObj;
    this.observation =
      getCommentsLabel(payment, billingType, shipping, change, deliveryDeadline, selectedInstallment);
    this.paymentMethod = parseInt(payment?.id) || undefined;
    if (shippingId) {
      this.shippingOption = shippingId;
    } else {
      this.shippingOption = parseInt(shipping?.id) || undefined;
    }
    this.paymentCard = paymentCard?.card;

    if (this.paymentCard && selectedInstallment?.value) {
      this.paymentCard.installments = parseInt(selectedInstallment?.value) || 1;
    }

    return this;
  },

  addItems({ items }) {
    const orderItems = [];
    items.forEach((v) => {
      const priceBig = Big(
        v.item?.listPrice || v.item?.promotionalPrice || v.item.price || 0,
      );
      const quantityBig = Big(v.quantity ?? 0);
      orderItems.push({
        item: parseInt(v.item.id),
        quantity: quantityBig.toNumber(),
        price: priceBig.toFixedNumber(),
        total: priceBig.mul(quantityBig).toFixedNumber(),
      });
    });
    this.items = orderItems;
    return this;
  },

  addAmount(subTotal, freight, discount) {
    this.total = getOrderTotal(subTotal, freight, discount);
    return this;
  },

  addUserNotes(text) {
    if (text && text.length > 4) {
      this.observation =
        `${this.observation}\n\nObservação do cliente: ${text}`;
    }
    return this;
  },

  addCouponCode(text) {
    this.discountCouponCode = text || undefined;
    return this;
  },

  getPaymentCardData() {
    if (this.paymentCard?.encrypted) {
      const billingAddress = this.paymentCard.billingAddressId;
      const brand = this.paymentCard.cardBrand
        ? window.$utils.toTitleCase(this.paymentCard.cardBrand)
        : undefined;
      return {
        cardBrand: brand,
        cardHolderName: this.paymentCard.cardHolderName,
        cardSecurityCode: this.paymentCard.cardSecurityCode,
        expiryMonth: this.paymentCard.expiryMonth,
        expiryYear: this.paymentCard.expiryYear,
        encrypted: this.paymentCard.encrypted,
        authenticationMethodId: this.paymentCard.authenticationMethodId,
        authenticationMethodType: this.paymentCard.authenticationMethodType,
        store: this.paymentCard.store,
        type: this.paymentCard.type,
        installments: this.paymentCard.installments,
        billingAddressId: billingAddress ? parseInt(billingAddress) : undefined,
        cardLastDigits: this.paymentCard.cardLastDigits,
        userPaymentCardId: this.paymentCard.userPaymentCardId,
      };
    }
    return this.paymentCard;
  },

  async data() {
    const device = {
      deviceId: await browserHash(),
    };
    const paymentCard = this.getPaymentCardData();
    if (paymentCard && device.deviceId) {
      paymentCard.device = device;
    }

    return JSON.parse(JSON.stringify({
      addressId: this.addressId,
      discountCouponCode: this.discountCouponCode,
      items: this.items,
      observation: this.observation,
      paymentCard,
      paymentMethod: this.paymentMethod,
      shippingOption: this.shippingOption,
      total: this.total,
    }));
  },
};

export default OrderFactory;
