import Big from "../../plugins/big";

function getBillingTypeLabel(type, gateway, multiCardGateways = false) {
  if (["credit", "debit"].includes(type)) {
    return multiCardGateways
      ? `Cartão (${type === "credit" ? "Crédito" : "Débito"})`
      : "Cartão";
  }
  if (type === "link") {
    return "Link de pagamento";
  }
  if (type === "pix") {
    return "PIX";
  }
  return type;
}

function PaymentFactory(options, instance) {
  this.options = options?.map((item) => ({
    ...item,
    ...(item.supportedNetworks && {
      supportedNetworks: JSON.parse(item.supportedNetworks),
    }),
    ...(item.supportedTypes && {
      supportedTypes: JSON.parse(item.supportedTypes),
    }),
    ...(item.installments && {
      installments: JSON.parse(item.installments),
    }),
  }));
  this.payment = instance?.payment || null;
  this.network = instance?.network || null;
  this.billingType = instance?.billingType || null;
  this.change = instance?.change || null;
  this.paymentCard = instance?.paymentCard || {
    card: null,
    label: "",
    brand: "",
  };
  this.selectedInstallment = instance?.selectedInstallment || {
    value: 1,
    label: "Preço à vista",
  };
}

PaymentFactory.prototype = {
  setPayment(v) {
    this.payment = JSON.parse(JSON.stringify(v));
    this.network = null;
    this.billingType = null;
    this.change = null;
    this.paymentCard = {
      card: null,
      label: "",
      brand: "",
    };
    this.selectedInstallment = {
      value: 1,
      label: "Preço à vista",
    };
  },

  setNetwork(v) {
    this.network = v?.value;
  },

  setBillingType(v) {
    this.billingType = v?.value;
  },

  setPaymentCard(args) {
    this.paymentCard = {
      card: args?.card ?? null,
      label: args?.label ?? "",
      brand: args?.brand ?? "",
    };
  },

  setChange(value, total) {
    if (value) {
      this.change = {
        value,
        total,
      };
    }
  },

  setSelectedInstallment(v) {
    this.selectedInstallment = v;
  },

  changeAmount() {
    if (this.change) {
      const v = this.change?.value - this.change?.total;
      const fail = this.change?.value !== -1 && v < 0;
      let title = "";
      if (this.change?.value === -1) {
        title = "Sem troco";
      } else {
        title = fail
          ? "Valor deve ser maior que o total do pedido"
          : `Troco ${window.$utils.BRL(v || 0)}`;
      }
      return {
        action: fail ? "mdi-alert-circle-outline" : "mdi-check-circle-outline",
        class: fail ? "red--text" : "green--text",
        title,
      };
    }
    return null;
  },

  installments() {
    const notValid = !["card", "gateway"].includes(this.payment?.type);
    if (notValid || this.billingType !== "credit") {
      return null;
    }
    if (this.payment.type === "gateway" && !this.paymentCard?.card) {
      return null;
    }
    if (!this.selectedInstallment) {
      return null;
    }
    return {
      title: this.selectedInstallment.label,
      subtitle: "Parcelamento",
    };
  },

  installmentsOptions(totalValue = 0) {
    const notValid = !["card", "gateway"].includes(this.payment?.type);
    if (notValid || this.billingType !== "credit") {
      return null;
    }
    if (!this.payment?.installments) {
      return null;
    }

    const options = this.payment.installments;
    const numberInstallments = parseInt(options.number_installments);
    const minInstallmentValue = Big(options.min_installment_value);
    const total = Big(totalValue);

    const list = [{
      value: 1,
      label: "Preço à vista",
    }];

    if (numberInstallments > 1) {
      const maxInstallment = Math.min(
        numberInstallments,
        Math.floor(total.div(minInstallmentValue).toNumber()),
      );
      for (let i = 2; i <= maxInstallment; i += 1) {
        const value = total.div(i).toNumber();
        const label = `Em ${i}x de ${window.$utils.BRL(value)} sem juros`;
        list.push({
          value: i,
          label,
        });
      }
    }

    return list;
  },

  isReady() {
    if (this.payment?.type === "simple") {
      if (this.change?.value === -1) {
        return true;
      }
      const v = this.change?.value - this.change?.total;
      return this.change?.value && v >= 0;
    }
    if (this.payment?.type === "card") {
      return ["credit", "debit"].includes(this.billingType);
    }
    if (this.payment?.type === "gateway") {
      const types = this.payment.supportedTypes ?? [];
      if (types.includes("link") || types.includes("pix")) {
        return ["link", "pix"].includes(this.billingType);
      }
      if (types.includes("credit") || types.includes("debit")) {
        const valid = ["credit", "debit"].includes(this.billingType);
        return valid && !!this.paymentCard?.card;
      }
    }
    return false;
  },

  get currentBillingType() {
    return this.billingType;
  },

  get paymentTitleClass() {
    return !this.payment ? "red--text" : undefined;
  },

  get paymentTitle() {
    return this.payment?.label || "Escolha a forma de pagamento";
  },

  get paymentOptions() {
    const list = this.options ?? [];
    return list.filter((val, idx, array) => {
      return array.findIndex((v) => v.type === val.type) === idx;
    });
  },

  get networkTitleClass() {
    const type = this.payment?.type;
    if (type === "simple") {
      return !this.change ? "red--text" : undefined;
    }
    if (type === "card") {
      const invalid = !["credit", "debit"].includes(this.billingType);
      return invalid ? "red--text" : undefined;
    }
    if (type === "gateway") {
      const types = this.payment.supportedTypes ?? [];
      if (types.includes("link") || types.includes("pix")) {
        const valid = ["link", "pix"].includes(this.billingType);
        return valid ? "light-blue--text" : "red--text";
      }
      if (types.includes("credit") || types.includes("debit")) {
        const valid = ["credit", "debit"].includes(this.billingType);
        return !valid || !this.paymentCard?.card ? "red--text" : undefined;
      }
    }
    return undefined;
  },

  get networkTitle() {
    if (this.payment?.type === "simple") {
      const value = this.change?.value === -1
        ? this.change?.total
        : this.change?.value;
      return this.change
        ? `Dinheiro total ${window.$utils.BRL(value)}`
        : "Informe o valor total para o troco";
    }
    if (this.payment?.type === "card") {
      if (!this.billingType) {
        return "Selecione uma opção de cartão";
      }
      return this.billingType === "credit" ? "Crédito" : "Débito";
    }
    if (this.payment?.type === "gateway" && this.billingType) {
      if (this.billingType === "link") {
        return "Link de pagamento";
      }
      if (this.billingType === "pix") {
        return "PIX";
      }
      if (["credit", "debit"].includes(this.billingType)) {
        const l = this.billingType === "credit" ? "Crédito" : "Débito";
        return this.paymentCard?.label || `Informar dados do cartão (${l})`;
      }
    }
    return "Selecionar pagamento";
  },

  get networkSubtitle() {
    if (
      this.payment?.type === "gateway" &&
      (this.billingType === "link" || this.billingType === "pix")
    ) {
      return this.gatewayPaymentInfo;
    }
    return null;
  },

  get networkOptions() {
    if (this.payment?.type === "gateway") {
      const gateways = this.options?.filter((v) => v.type === "gateway");

      const multiCardGateways = gateways.reduce?.((p, c) => {
        const type = c.supportedTypes[0];
        return ["credit", "debit"].includes(type) ? p + 1 : p;
      }, 0) > 1;

      return gateways.map((v) => {
        const types = v.supportedTypes;
        return {
          title: getBillingTypeLabel(types[0], v.gateway, multiCardGateways),
          billingType: types[0],
          value: v,
        };
      });
    }
    if (!this.payment?.supportedTypes) {
      return null;
    }
    if (this.payment?.type === "card") {
      const types = this.options?.filter((v) => v.type === "card");
      return types.map((v) => {
        const title = v.supportedTypes[0] === "credit" ? "Crédito" : "Débito";
        return {
          title,
          billingType: v.supportedTypes[0],
          value: v,
        };
      });
    }
    const json = this.payment.supportedTypes;
    return json.map((v) => {
      const title = v === "credit" ? "Crédito" : "Débito";
      return { title, value: v };
    });
  },

  get networkVisible() {
    return this.payment;
  },

  get gatewayPaymentInfo() {
    if (!this.payment?.gateway) {
      return "";
    }
    const types = this.payment.supportedTypes ?? [];
    let v = "";
    if (types.includes("pix")) {
      v = " PIX";
    }
    return `Ao finalizar o pedido, você será direcionado para a tela de pagamento${v}`;
  },
};

export default PaymentFactory;
