/* eslint-disable no-underscore-dangle */
import http from "@/http";
import Big from "@/plugins/big";
import { DISCOUNT_RULES } from "@/http/apollo/schema/discountRulesSchema";
import { PagSeguro } from "@/lib/gateways/pagseguro";
import { Getnet } from "@/lib/gateways/getnet";
import schema from "@/http/apollo/schema";
import { OrderFactory, PaymentFactory, ShippingFactory } from "@/lib/order";
import { AddressFactory, parser } from "@/lib/account";
import { useAccountStore } from "@/storex/accountStore";
import { useConfigStore } from "@/storex/configStore";
import { useLocationStore } from "@/storex/checkout/locationStore";
import { useCouponStore } from "@/storex/checkout/couponStore";

export default {
  removePayment({ getters }) {
    const payment = getters.paymentMethods;
    payment.setPayment(null);
  },

  async setBillingType({ getters }, value) {
    const payment = getters.paymentMethods;
    payment.setBillingType(value);
  },

  setChange({ getters }, { value, total }) {
    const payment = getters.paymentMethods;
    payment.setChange(value, total);
  },

  setPayment({ getters }, value) {
    const payment = getters.paymentMethods;
    payment.setPayment(value);
  },

  setPaymentCard({ getters }, value) {
    const payment = getters.paymentMethods;
    payment.setPaymentCard(value);
  },

  async updatePaymentCardData({ getters, dispatch }) {
    try {
      let cardData = getters.paymentMethods?.paymentCard?.card;
      cardData = await dispatch("encryptCard", cardData);
      const result3DS = await dispatch("authenticate3DS", cardData);
      if (result3DS) {
        cardData = result3DS;
      }
      const payment = getters.paymentMethods;
      payment.setPaymentCard({
        ...payment.paymentCard,
        card: cardData,
      });
    } catch (e) {
      const text = e?.message;
      window.$vm.$snackbar.show({ text, action: { text: "OK" } });
    }
  },

  setShipping({ getters }, value) {
    const shipping = getters.shippingOptions;
    shipping.setShipping(value);
  },

  setAddress({ getters }, value) {
    const shipping = getters.shippingOptions;
    shipping.setAddress(value);
  },

  setAmount({ getters }, value) {
    const shipping = getters.shippingOptions;
    shipping.setAmount(value);
  },

  setSelectedInstallment({ getters }, value) {
    const payment = getters.paymentMethods;
    payment.setSelectedInstallment(value);
  },

  async authenticate3DS({ getters }, cardData) {
    const { gateway, supportedTypes } = getters.paymentMethods?.payment || {};
    if (gateway === "pagseguro" && supportedTypes?.includes("debit")) {
      const id = getters.paymentMethods?.payment?.id;
      const storeId = window.Smart?.store?.id;
      const freight = getters.shippingOptions?.shipping?.amount ?? 0;
      const order = new OrderFactory().addAmount(
        getters.subTotal,
        freight,
        getters.computedDiscount.value,
      );

      const pagseguroGateway = new PagSeguro(storeId, id);
      return pagseguroGateway.authenticate3DS(
        cardData,
        Big(order.total).mul(100).toFixedNumber(),
      );
    }

    return null;
  },

  async encryptCard({ getters }, cardData) {
    const gateway = getters.paymentMethods?.payment?.gateway;
    const id = getters.paymentMethods?.payment?.id;
    const storeId = window.Smart?.store?.id;

    if (gateway === "pagseguro") {
      const pagseguroGateway = new PagSeguro(storeId, id);
      return pagseguroGateway.encryptCard(cardData);
    }

    if (gateway === "getnet") {
      const getnetGateway = new Getnet(storeId, id);
      return getnetGateway.encryptCard(cardData);
    }

    throw new Error("Gateway não suportado");
  },

  async getCofreCard(
    { getters },
    { cardId, cardSecurityCode, billingAddressId, userPaymentCardId },
  ) {
    const storeId = window.Smart?.store?.id;
    const gateway = getters.paymentMethods?.payment?.gateway;
    const id = getters.paymentMethods?.payment?.id;

    if (!storeId || !id || gateway !== "getnet") {
      return null;
    }

    const url = `api/store/${storeId}/payment-methods/${id}/cards/cofre/${cardId}`;
    const data = await http.get(url).then((r) => r.data);
    return {
      cardBrand: data.brand,
      cardHolderName: data.cardholder_name,
      cardSecurityCode,
      expiryMonth: `${data.expiration_month}`,
      expiryYear: `${data.expiration_year}`,
      encrypted: data.number_token,
      store: false,
      type: "CREDIT_CARD",
      installments: 1,
      billingAddressId,
      cardLastDigits: data.last_four_digits,
      userPaymentCardId,
    };
  },

  getDiscountRules({ commit }) {
    const config = useConfigStore();
    window.$apollo.query({
      query: DISCOUNT_RULES,
      variables: { storeId: config.currentStore },
    }).then(({ data }) => {
      const defaultRule = data.discountRules.find?.((v) => {
        return v.isDefault;
      });
      commit("discountRules", data.discountRules);
      commit("defaultDiscountRule", defaultRule);
    }).catch(() => null);
  },

  async loadData({ commit, getters, rootGetters }) {
    commit("subTotal", rootGetters["shopping/shopping"].total);
    commit(
      "totalWithoutPromoItems",
      rootGetters["shopping/shopping"].totalWithoutPromoItems,
    );
    await window.$vm.$nextTick();
    const USD = window.$vm.$utils.USD(getters.subTotal);
    const BRL = window.$vm.$utils.BRL(getters.subTotal);
    commit("subTotalLabel", window.$vm.$i18n.locale === "en" ? USD : BRL);
  },

  async fetchData({ dispatch, commit, getters }) {
    const config = useConfigStore();
    try {
      const {
        data: { payments, address },
      } = await window.$vm.$apollo.query({
        query: schema.store.PAYMENTS_ADDRESS,
        variables: { id: config.currentStore },
      });

      commit(
        "shippingOptions",
        new ShippingFactory(
          payments.shippingOptions,
          address,
          getters.shippingOptions
        )
      );
      commit(
        "paymentMethods",
        new PaymentFactory(payments.paymentMethods, getters.paymentMethods)
      );
    } catch {
      // ignore
    } finally {
      commit("loading", false);
      dispatch("getDiscountRules");
    }
  },

  async validateAddressLocation(_, id) {
    const locationStore = useLocationStore();
    const coords = locationStore.userLocation?.coords;

    if (!locationStore.useLocationCheckout || !coords) {
      return;
    }

    try {
      const {
        data: { address },
      } = await window.$vm.$apollo.query({ query: schema.user.ADDRESS });
      const list = new AddressFactory(address);

      const addr = list.filter((v) => {
        if (!id) {
          return v.__typename !== "UserAddress";
        }
        return v.id === id;
      })?.[0];

      if (addr) {
        addr.latitude = coords.latitude;
        addr.longitude = coords.longitude;

        await window.$vm.$apollo.mutate({
          mutation: !id
            ? schema.user.USER_MUTATION
            : schema.user.SET_ENDERECO,
          variables: parser.address(addr),
        });
      }
    } catch {
      // e
    }
  },

  async submitOrder({ dispatch, commit, getters, rootGetters }) {
    const accountStore = useAccountStore();
    const locationStore = useLocationStore();
    const couponStore = useCouponStore();

    try {
      commit(
        "appLoadingLabel",
        "message.loading.order.processing",
        { root: true },
      );
      commit("appLoading", true, { root: true });

      const {
        data: { me },
      } = await window.$vm.$apollo.query({ query: schema.user.ME });

      if (!me?.emailVerifiedAt) {
        accountStore.userVerified = false;
        return;
      }
      const config = useConfigStore();

      const freight = getters.shippingOptions?.shipping?.amount ?? 0;
      const factory = new OrderFactory()
        .addShippingAndPayment(
          getters.shippingOptions,
          getters.paymentMethods,
          {
            deliveryDeadline: locationStore.deliveryDeadline,
            shippingType: locationStore.shippingType,
            shippingId: locationStore.shippingId,
          },
        )
        .addItems(rootGetters["shopping/shopping"], config.currentStore)
        .addAmount(
          getters.subTotal,
          freight,
          getters.computedDiscount.value,
        )
        .addUserNotes(getters.userNotes);

      if (couponStore.value?.length && couponStore.isValidCoupon) {
        factory.addCouponCode(couponStore.couponCode.couponCode);
      }

      const order = await factory.data();
      await dispatch("validateAddressLocation", order.addressId);

      const {
        data: { orderId },
      } = await window.$vm.$apollo.query({
        query: schema.store.PEDIDO_MUTATION,
        variables: order,
      });

      couponStore.reset();
      commit("order-success", true, { root: true });
      window.$vm.$router.replace({ name: "order", query: { id: orderId } });
      dispatch("shopping/removeAllShoppingCart", null, { root: true });
    } catch {
      dispatch("updatePaymentCardData");
    } finally {
      commit("appLoading", false, { root: true });
    }
  },

  validateOnSubmit({ dispatch, getters }) {
    if (!getters.orderReady) {
      window.$vm.$messages.warning({ text: "O pedido ainda possui pendências" });
      return;
    }

    window.$vm.$alert.show({
      t: "alert.message.checkout_order",
      actions: [
        { t: "alert.button.cancel" },
        {
          t: "alert.button.checkout",
          onPress() {
            setTimeout(() => dispatch("submitOrder"), 100);
          },
        },
      ],
    });
  },
};
